import React, { useState, useEffect } from "react";
import { Box, Stack } from "@mui/material";
import LinkGroup from "@/components-modern/molecules/link-group/LinkGroup";
import GenericDialog from "@/components-modern/molecules/generic-dialog/GenericDialog";
import ContentHeadline from "@/components/Library/Resources/ContentHeadline";
import { LinkGroupLink } from "@/hooks/Library/useBottomNav"; // Add this import

interface MiddleBoxProps {
  linkGroupLinksMobile: LinkGroupLink[];
  linkGroupLinksDesktop: LinkGroupLink[];
  hasPracticeQuestions: boolean;
  pageData: {
    practiceEventProps?: {
      numOfQuestions: number;
      [key: string]: unknown;
    };
    [key: string]: unknown;
  };
  pathParams?: Record<string, string | string[]>;
  pathname?: string;
  trackClick: (args: { eventLabel: string; eventProps?: Record<string, unknown> }) => void;
}

const GuidesDialog = ({ open, handleClose, pathname, pathParams }): JSX.Element => {
  return (
    <GenericDialog
      open={open}
      onCancel={handleClose}
      id="mobile-guides-dialog"
      dialogContentStyles={{
        justifyContent: "flex-start",
        flexDirection: "column",
      }}>
      <ContentHeadline key={pathParams.unitSlug} pathname={pathname} pathParams={pathParams} />
    </GenericDialog>
  );
};

const MiddleBox: React.FC<MiddleBoxProps> = ({
  linkGroupLinksMobile,
  linkGroupLinksDesktop,
  hasPracticeQuestions,
  pageData,
  pathname,
  pathParams,
  trackClick,
}) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <Stack flexDirection="row" justifyContent="center" alignItems="center" width="70%">
      {/* Mobile bottom nav */}
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <GuidesDialog
          open={open}
          handleClose={() => setOpen(false)}
          pathname={pathname}
          pathParams={pathParams}
        />
        <LinkGroup
          links={linkGroupLinksMobile}
          linkVariant="contained"
          onClick={(l) => {
            if (l.id === "practiceMore" && !hasPracticeQuestions) {
              l.onClick?.();
            } else if (l.id === "guideBottomNavLink") {
              setOpen(true);
            } else {
              trackClick({
                eventLabel: l.label,
                eventProps: { ...(pageData?.practiceEventProps || {}) },
              });
            }
          }}
        />
      </Box>
      {/* Desktop bottom nav */}
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <LinkGroup
          links={linkGroupLinksDesktop}
          linkVariant="contained"
          onClick={(l) => {
            if (l.id === "practiceMore" && !hasPracticeQuestions) {
              l.onClick?.();
            } else {
              trackClick({
                eventLabel: l.label,
                eventProps: { ...(pageData?.practiceEventProps || {}) },
              });
            }
          }}
        />
      </Box>
    </Stack>
  );
};

export default MiddleBox;
