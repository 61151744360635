// "use client";

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import dynamic from "next/dynamic";
import { Box } from "@mui/material";
import NavLinks from "@/components-modern/molecules/nav-menu/NavLinks";
import ContentHeadline from "@/components/Library/Resources/ContentHeadline";
import { useHasCramMode } from "@/hooks/Library/useHasCramMode";
import { useAuthentication } from "@/utils/useAuthentication";

const GuidesLeftBanner = dynamic(() => import("@/components/Layouts/NewNavbar/GuidesLeftBanner"), {
  ssr: false,
});

// eslint-disable-next-line max-lines-per-function
const NavContent = ({
  isSidebarOpen,
  closeSidebar, // technically this fn toggles, but you can only see this component if open, so it in practice will only ever close
  // need to inject these to support both pages and app router
  pathname = "",
  pathParams = {},
  communitySlug = "",
}: {
  isSidebarOpen?: boolean;
  closeSidebar: () => void;
  pathname?: string;
  pathParams: Record<string, string | string[]> | { [key: string]: string | string[] };
  communitySlug?: string | string[] | undefined;
}): JSX.Element => {
  const hasCramMode = useHasCramMode();
  const { isSuperAdmin } = useAuthentication();
  const unitSlug = Array.isArray(pathParams?.unitSlug) ? pathParams?.unitSlug?.[0] : pathParams?.unitSlug;
  const shouldHideGuidesLeft = hasCramMode || isSuperAdmin;

  return (
    <Box
      data-nosnippet // this is to prevent google from indexing the sidebar content
      sx={{
        py: 5,
        px: 3,
        width: { xs: "80vw", md: isSidebarOpen ? "350px" : "0px !important" },
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", width: "100%" }}>
        {!shouldHideGuidesLeft && (
          <Box
            sx={{
              mb: 0,
              display: { xs: shouldHideGuidesLeft ? "none" : "block", md: "none" },
            }}>
            <GuidesLeftBanner />
          </Box>
        )}

        <ContentHeadline
          key={unitSlug}
          closeSidebar={closeSidebar}
          pathname={pathname}
          pathParams={pathParams}
        />
        {communitySlug && <NavLinks pathname={pathname} pathParams={pathParams} />}
      </Box>
    </Box>
  );
};

export default NavContent;
