import React, { useState } from "react";
import { Box, Stack, Collapse, Typography } from "@mui/material";
import useContentHeadline from "@/hooks/Library/useContentHeadline";
import { NavLink } from "@/components-modern/molecules/nav-menu/NavLink";
import { Button } from "@/components-modern/atoms/button/Button";
import { faCaretDown, faCaretRight, faChevronsLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSpecificTheme } from "@/hooks/useSpecificTheme";
import { useTrackEvent } from "@/services";
import { navbarAnalyticsInfo } from "@/utils/analyticsConstants";
import SaveSubjectButton from "@/components-modern/molecules/save-subject-button/SaveSubjectButton";
import { getUnitNameWithoutNumber } from "@/utils/Library/contentTypes";
import { ComponentType, PageType, useSimpleCtaExperiment } from "@/hooks/useSimpleCtaExperiment";

const isAPSubject = (subjectName: string): boolean => {
  if (!subjectName) {
    return false;
  }
  return subjectName.startsWith("AP ");
};

type ContentHeadlineProps = {
  closeSidebar?: () => void;
  pathParams: Record<string, string | string[]> | { [key: string]: string | string[] };
  pathname: string;
};

// eslint-disable-next-line max-lines-per-function
const ContentHeadline = ({ closeSidebar, pathParams, pathname = "" }: ContentHeadlineProps): JSX.Element => {
  const theme = useSpecificTheme();
  const { trackEvent } = useTrackEvent();
  const trackNavClick = (label) => {
    trackEvent({ ...navbarAnalyticsInfo.NAV_ITEM_CLICK, label });
  };
  const { subject, unitsAndTopicsObj, unitSlug } = useContentHeadline(pathParams, pathname);

  const activeUnitSlug = Array.isArray(unitSlug) ? unitSlug[0] : unitSlug;
  const [openUnitSlug, setOpenUnitSlug] = useState(activeUnitSlug);
  const showSave = useSimpleCtaExperiment(ComponentType.OTHER, PageType.GENERAL);

  return (
    <Stack
      flexWrap="wrap"
      flexDirection="column"
      alignItems="stretch"
      width="100%"
      color={subject?.color}
      gap="8.93px"
      p={{ xs: "none", md: "0" }}
      mb={{ xs: "20px", md: "0" }}>
      {/* On mobile this lives in top nav instead of within this component */}
      <Box sx={{ my: 1, display: { xs: "none", md: "block" } }}>
        {showSave && <SaveSubjectButton pathParams={pathParams} />}
      </Box>
      {/* This is the select for subjects */}
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ flex: 1 }}>
          <Typography>{subject?.name}</Typography>
        </Box>
        {closeSidebar && (
          <Button onClick={closeSidebar} variant="textSecondary">
            <FontAwesomeIcon
              icon={faChevronsLeft}
              style={{ width: ".875rem", color: "inherit", marginLeft: "5px" }}
            />
          </Button>
        )}
        {/* This is the list of ordered units and nested topics */}
      </Box>
      <Box
        sx={{
          position: "relative",
          maxHeight: "60vh",
          maskImage: "linear-gradient(to bottom, black calc(100% - 60px), transparent 100%)",
          WebkitMaskImage: "linear-gradient(to bottom, black calc(100% - 60px), transparent 100%)",
          overflowY: "scroll",
          overflowX: "hidden",
          scrollbarWidth: "none", // Firefox
          msOverflowStyle: "none", // Internet Explorer 10+
          "&::-webkit-scrollbar": {
            width: "0px",
            background: "transparent", // Chrome/Safari/Webkit
          },
        }}>
        <Box sx={{ paddingBottom: "25px" }}>
          {unitsAndTopicsObj?.map((unit) => (
            <Box key={unit.id}>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Button
                  variant="textSecondary"
                  onClick={() => setOpenUnitSlug(openUnitSlug === unit.slug ? "" : unit.slug)}
                  size="large"
                  sx={{
                    color: theme.palette.text.secondary,
                    textAlign: "left",
                    display: "block",
                    width: "100%",
                    maxWidth: { xs: "100%", md: "312px" },
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    mb: { xs: 1, md: 2 },
                  }}>
                  {openUnitSlug === unit.slug ? (
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      style={{ width: "9px", color: "inherit", marginRight: "8px" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCaretRight}
                      style={{ width: "7px", color: "inherit", marginRight: "8px" }}
                    />
                  )}
                  {isAPSubject(subject?.name) ? unit.name : getUnitNameWithoutNumber(unit)}
                </Button>
              </Box>
              <Collapse in={openUnitSlug === unit.slug} timeout="auto" unmountOnExit sx={{ width: "90%" }}>
                {unit.formattedTopics?.map((topic, i) => {
                  const isActive = pathname.includes(topic.url);
                  return (
                    <NavLink
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${topic.id}-${i}`}
                      onClick={() => trackNavClick(topic.name)}
                      showLoading
                      variant="textSecondary"
                      link={{ key: topic.id, label: topic.name, href: topic.url || "" }}
                      isActive={isActive}
                      linkStyles={{
                        width: "100%",
                        marginBottom: "8px",
                        marginTop: i === 0 ? "0px" : "8px",
                        marginRight: "8px",
                      }}
                    />
                  );
                })}
              </Collapse>
            </Box>
          ))}
        </Box>
      </Box>
    </Stack>
  );
};

export default React.memo(ContentHeadline);
