import { useState, useEffect } from "react";
import useProfile from "@/utils/useProfile";
import { CURRENT_SCHOOL_YEAR } from "@hours/constants";
import { useModernDispatch } from "@/store/hooks";
import libraryApi from "@/store/api/graphql/library.extended.api";
import { useHasCramMode } from "@/hooks/Library/useHasCramMode";
import { useTrackEvent } from "@/services";
import { CLICK_CLOSE_SAVE_SUBJECT_PAYWALL, VIEW_SAVE_SUBJECT_PAYWALL } from "@/utils/analyticsConstants";
import { useRouter, usePathname, useSearchParams } from "next/navigation";
import { isToday } from "date-fns";
import useUpdateQueryParams from "../useUpdateQueryParams";

const NUM_OF_SECONDS_DELAY = 60;

const getSearchableSubjects = async ({ dispatch }) =>
  dispatch(
    libraryApi.endpoints.getSearchableSubjects.initiate({
      filter: {
        active: { eq: true },
        generationMetadataBranch: { ne: "branch" },
      },
      sortBy: {
        name: 1,
      },
    })
  );

const getSubjectSlug = (params) => {
  if (!params) return "";
  const { communitySlug, subjectSlug, slug } = params;
  return communitySlug || subjectSlug || slug?.[0];
};

const useSaveSubjectPaywall = (): {
  profileSubjects: string[];
  subjects: string[];
  isLoading: boolean | undefined;
  shouldShowSaveSubjectPaywall: boolean;
  open: boolean;
  handleClose: () => void;
} => {
  const { trackEvent } = useTrackEvent();
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { removeQueryParamFromUrl } = useUpdateQueryParams();

  const savingSubjectsQuery = searchParams?.get("saving-subjects");
  const subjectSlug = getSubjectSlug(searchParams);
  const [subjects, setSubjects] = useState([]);
  const hasCramMode = useHasCramMode();
  const { profile, isProfileLoading } = useProfile();
  const [isLoading, setIsLoading] = useState(isProfileLoading);
  const [open, setOpen] = useState(false);
  const dispatch = useModernDispatch();
  const profileSubjects = profile?.subjectsAndExams?.[CURRENT_SCHOOL_YEAR] || [];

  const handleClose = () => {
    trackEvent(CLICK_CLOSE_SAVE_SUBJECT_PAYWALL);
    // when we close the modal, remove params related to signing up through this button
    // prevents duplicate paywall views if user hits back
    const newUrl = removeQueryParamFromUrl(pathname ?? "", ["saving-subjects", "subjects"]);
    router.replace(newUrl);
    setOpen(false);
  };
  // only trigger the autoopen after 60 sec on guide pages
  const isGuidePage = pathname?.includes("/study-guide");
  const isPurchasePage = pathname?.includes("/unlock");
  useEffect(() => {
    if (isPurchasePage) return () => {};
    if (isGuidePage && !profile && !isProfileLoading) {
      const localStorageKey = "subSelect";
      const timeout = setTimeout(() => {
        // determine if user has already seen the prompt today
        const subSelect = localStorage.getItem(localStorageKey);
        if (subSelect && isToday(new Date(parseInt(subSelect, 10)))) {
          return;
        }
        setOpen(true);
        trackEvent({
          ...VIEW_SAVE_SUBJECT_PAYWALL,
          other: {
            autoOpen: true,
          },
        });
        // mark this modal as seen for today
        localStorage.setItem(localStorageKey, Date.now().toString());
      }, NUM_OF_SECONDS_DELAY * 1000);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [isProfileLoading, profile, isGuidePage]);

  useEffect(() => {
    // this param is set to success on successful sign in on /signin
    if (!isPurchasePage && !isProfileLoading && savingSubjectsQuery === "success") {
      setOpen(!hasCramMode);
    }
  }, [isProfileLoading, hasCramMode, profile?._id, savingSubjectsQuery]);

  useEffect(() => {
    setIsLoading(true);
    getSearchableSubjects({ dispatch })
      .then((res) => {
        const data = res.data.getSearchableSubjects;
        let subjectNames = [];

        // prefer use of profile subs
        const profileSubjectNames = data
          ?.filter((sub) => profileSubjects?.includes(sub.id))
          ?.map((sub) => `${sub.emoji} ${sub.name}`);

        subjectNames = profileSubjectNames;
        // if there are no save subjects on profile, use current subject
        if (profileSubjectNames.length === 0) {
          subjectNames = data
            .filter((sub) => sub.slug === subjectSlug)
            .map((sub) => `${sub.emoji} ${sub.name}`);
        }

        setSubjects(subjectNames);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [profileSubjects?.length]);

  return {
    profileSubjects,
    subjects,
    isLoading,
    shouldShowSaveSubjectPaywall: !hasCramMode,
    open,
    handleClose,
  };
};

export default useSaveSubjectPaywall;
